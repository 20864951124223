import teacher1 from '../../img/teachers/teacher-1.webp'
import teacher2 from '../../img/teachers/teacher-2.webp'
import teacher3 from '../../img/teachers/teacher-3.webp'
import teacher4 from '../../img/teachers/teacher-4.webp'
import teacher5 from '../../img/teachers/teacher-5.webp'
import teacher6 from '../../img/teachers/teacher-6.webp'
import teacher7 from '../../img/teachers/teacher-7.webp'
import teacher8 from '../../img/teachers/teacher-8.webp'
import teacher9 from '../../img/teachers/teacher-9.webp'
import teacher10 from '../../img/teachers/teacher-10.webp'
import teacher11 from '../../img/teachers/teacher-11.webp'
import teacher12 from '../../img/teachers/teacher-12.webp'
import teacher13 from '../../img/teachers/teacher-13.webp'
import teacher14 from '../../img/teachers/teacher-14.webp'
import teacher15 from '../../img/teachers/teacher-15.webp'
import teacher16 from '../../img/teachers/teacher-16.webp'
import teacher17 from '../../img/teachers/teacher-17.webp'
import teacher18 from '../../img/teachers/teacher-18.webp'
import teacher19 from '../../img/teachers/teacher-19.webp'
import teacher20 from '../../img/teachers/teacher-20.webp'
import teacher21 from '../../img/teachers/teacher-21.webp'
import teacher22 from '../../img/teachers/teacher-22.webp'
import teacher23 from '../../img/teachers/teacher-23.webp'
import teacher24 from '../../img/teachers/teacher-24.webp'
import teacher25 from '../../img/teachers/teacher-25.webp'
import teacher26 from '../../img/teachers/teacher-26.webp'
import teacher27 from '../../img/teachers/teacher-27.webp'


export const TeacherInfo = [
    {
        id: 1,
        modal: 'teacher1',
        name: 'Сидорова Ирина Леонидовна',
        img: teacher1,
        post: 'Руководитель онлайн школы «Shine School»',
        info: [
            'Меня зовут Сидорова Ирина, и я руководитель онлайн школы английского языка «Shine School».', 
            'Наша школа создана для того, чтобы сделать обучение английскому языку удобным, доступным и интересным. Мы работаем с детьми и взрослыми и твердо уверены, что нет людей, неспособных к языкам!', 
            'Успех нашей онлайн школы состоит из следующих компонентов:',
            '· профессиональные преподаватели, получающие удовольствие от своей работы,',
            '· «Живое» общения преподавателя и группы,',
            '· уникальная методика обучения, проверенная долгим опытом (более 15 лет офлайн и онлайн работы),',
            '· аутентичные учебные пособия,',
            '· использование современных технологий (мультимедиа, электронные учебные пособия и т.д.),',
            '· демократичные цены и возможность выбора удобного пакета оплаты.',
            'Мы твердо уверены, что онлайн обучение может быть качественным, результативным и доступным! Хотите в этом убедится? Присоединяйтесь к нам!',
        ],
    },
    {
        id: 2,
        modal: 'teacher2',
        name: 'Кан Ирина Олеговна',
        img: teacher2,
        post: 'Aкадемический директор, методист онлайн школы «Shine School»',
        info: [
            'Я преподаю английский язык уже почти 20 лет и нежно люблю свою профессию. За это время я работала с учениками разного возраста и в разном формате: в вузе со студентами, с детьми от 5 лет в частной школе, с подростками и взрослым в языковых центрах и онлайн.', 
            'Мой профиль — подготовка к международным экзаменам и ЕГЭ. Мои ученики успешно сдают кембриджские экзамены (PET, FCE, Business English Certificate), а также ЕГЭ на 80+. Не передать словами мою радость, когда мои ученики добиваются таких высоких результатов!', 
            'Имею диплом преподавателя английского языка с отличием по специальности лингвистика, сертификаты международных экзаменов TOEFL, TOEIC С1(Advanced).',
            'Прошла курс обучения в языковой школе в Лондоне, участница American English E-Teacher Program. Прохожу международную сертификацию по методике преподавания TESOL (Arizona State University). Очень люблю учиться и учить онлайн и по опыту знаю, что каждый может достичь своей цели с помощью онлайн-обучения и открыть для себя мир без границ!',
        ]
    },
    {
        id: 3,
        modal: 'teacher3',
        name: 'Пухач Ирина Александровна',
        img: teacher3,
        post: 'Педагог онлайн школы «Shine School»',
        info: [
            'Меня зовут Пухач Ирина и я преподаю английский язык уже почти 15 лет. За это время я работала с учениками разного возраста: от 7 до 70 лет, и в разном формате: в языковой школе офлайн и онлайн, а также с корпоративными клиентами ведущих компаний России.', 
            'Я специализируюсь на подготовке учеников к международным экзаменам (PET, FCE, CAE, IELTS, LCCI) и государственной итоговой аттестации (ОГЭ и ЕГЭ). Итогами моей работы стали успешная сдача экзаменов, подтверждённые высокие уровни учеников и поступление в престижные ВУЗы России.',
            'Имею диплом преподавателя двух иностранных языков Самарского Государственного Педагогического Университета и сертификаты международных экзаменов FCE и CAE.',
            'Помимо регулярной практики английского языка, всегда повышаю свой профессиональный уровень в сфере методики преподавания: имею международные сертификаты Teaching Knowledge Test: Modules 1, 2, 3; прошла курс Teaching English Online от Кембриджского университета и др.',
            'Мой подход к обучению можно описать так: «Английский – легко и по делу.» Подбираю индивидуальный подход к каждому ученику, чтобы они смогли достигнуть своих целей в краткие сроки.',
        ]
    },

    {
        id: 4,
        modal: 'teacher4',
        name: 'Ирина Евгеньевна',
        img: teacher4,
        post: 'Педагог онлайн школы «Shine School»',
        info: [
            'В 2013 году я закончила Балтийский Федеральный Университет имени Иммануила Канта по специальности «лингвистика» (бакалавриат).',
            'В 2014 году успешно сдала Кембриджский экзамен САЕ (Certificate in Advanced English) и подтвердила уровень знания языка C1. Международный сертификат ТКТ (Teaching Knowledge Test), подтверждающий соответствие навыков преподавателя современным требованиям — ещё один важный этап в моей жизни. За плечами курсы английского в Лондоне и опыт преподавания языка детям и взрослым более 7-ти лет.',
            'Веду уроки у разных возрастных категорий: от детей 5-ти лет до взрослых. Специализируюсь не только на общем английском, но и на подготовке детей к линейке Кембриджских экзаменов.',
            'Постоянно совершенствуюсь в профессии, в 2020 году изучила тонкости ЕГЭ и особенности подготовки к нему оффлайн и онлайн (выдано удостоверение установленного образца).',
            'Несмотря на то, что все дети индивидуальны и требуют разного подхода в обучении, я искренне верю в то, что путь к сердцу ученика лежит через теплоту души, искренность, честность и открытость. Только так можно сделать процесс обучения психологически безопасным для учащегося и поддерживать его интерес к процессу.',
        ]
    },
    {
        id: 5,
        modal: 'teacher5',
        name: 'Елена Олеговна',
        img: teacher5,
        post: 'Педагог онлайн школы «Shine School»',
        info: [
            'Преподаю английский более 7 лет, как в России, так и за рубежом. Работала и стажировалась в Китае и Польше.',
            'Имею несколько международных сертификатов от университета Кэмбриджа по методике преподавания:',
            '· Certificate in English Language Teaching – Secondary (CELT-S Cambridge)',
            '· Certificate in English Language Teaching – Primary (CELT-P Cambridge)',
            '· Teaching English Online (Cambridge Assessment English.)',
            '· Cambridge Teaching Knowledge Test (TKT Module 1-3).',
            '· Cambridge TKT: Content and language integrated learning',
            '· Cambridge TKT: Young learners',
            '· Cambridge CELTA (Pyatigorsk, Russia).',
            '· Certificate in Teaching English to the Speakers of Other Languages',
            '· Teaching English as a second or foreign language (TEFL)',
            'Всегда стараюсь, чтобы мои уроки были увлекательным и веселыми, моя цель — вдохновить учеников активно использовать английский в повседневной жизни и научить их уверенно общаться в любой ситуации.',
        ]
    },
    {
        id: 6,
        modal: 'teacher6',
        name: 'Татьяна Генриховна',
        img: teacher6,
        post: 'Педагог онлайн школы «Shine School»',
        info: [
            'В 2013 году я закончила Балтийский Федеральный Университет имени Иммануила Канта по специальности «лингвистика» (бакалавриат).',
            'В 2014 году успешно сдала Кембриджский экзамен САЕ (Certificate in Advanced English) и подтвердила уровень знания языка C1. Международный сертификат ТКТ (Teaching Knowledge Test), подтверждающий соответствие навыков преподавателя современным требованиям — ещё один важный этап в моей жизни. За плечами курсы английского в Лондоне и опыт преподавания языка детям и взрослым более 7-ти лет.',
            'Веду уроки у разных возрастных категорий: от детей 5-ти лет до взрослых. Специализируюсь не только на общем английском, но и на подготовке детей к линейке Кембриджских экзаменов.',
            'Постоянно совершенствуюсь в профессии, в 2020 году изучила тонкости ЕГЭ и особенности подготовки к нему оффлайн и онлайн (выдано удостоверение установленного образца).',
            'Несмотря на то, что все дети индивидуальны и требуют разного подхода в обучении, я искренне верю в то, что путь к сердцу ученика лежит через теплоту души, искренность, честность и открытость. Только так можно сделать процесс обучения психологически безопасным для учащегося и поддерживать его интерес к процессу.',
        ]
    },
    {
        id: 7,
        modal: 'teacher7',
        name: 'Евгения Викторовна',
        img: teacher7,
        post: 'Педагог онлайн школы «Shine School»',
        info: [
            'Закончила педагогический университет по специальности преподаватель английского языка. С 2008 года работаю с детьми, подростками и взрослыми в частных школах России и Китая. Обожаю своих учеников! Мои студенты всегда невероятные, удивительные и потрясающие люди! Стараюсь им соответствовать, поэтому постоянно учусь и совершенствуюсь. Являюсь счастливым обладателем Кэмбриджских сертификатов IH, TKT, TKT YL, CAE.',
        ]
    },
    {
        id: 8,
        modal: 'teacher8',
        name: 'Зоя Владимировна',
        img: teacher8,
        post: 'Педагог онлайн школы «Shine School»',
        info: [
            'Я закончила Казанский Федеральный Университет по специальности переводчик.',
            'Преподаю английский более 7 лет. И имею опыт работы в языковых центрах (Россия, Китай), а так же в детских садах и младших общеобразовательных школах (Китай). Имею международный сертификат TEFL.',
            'Мне нравится наблюдать рост и прогресс своих учеников, когда от односложных ответов они переходят к развёрнутым предложениям, когда могут выразить своё мнение по тому или иному вопросу, когда они достигают поставленных целей.',
            'Так, например, двое из моих взрослых учеников прошли собеседование и получили должности бортпроводников, о которых мечтали.',
        ]
    },
    {
        id: 9,
        modal: 'teacher9',
        name: 'Анна Николаевна',
        img: teacher9,
        post: 'Педагог онлайн школы «Shine School»',
        info: [
            'Закончила педагогический институт по специальности » Учитель иностранных языков»',
            'Преподаю английский с 2019 года.',
            'Самый любимый возраст от 5 и до 15.',
            'Это так здорово, когда ребёнок, не знавший английский до этого, с нетерпением ждёт урока, где он будет петь, играть и конечно же получать новые знания!',
            'Всегда восхищаюсь тому, как дети быстро всё запоминаю, когда это интересно, весело и ни капельки не скучно!',
        ]
    },
    {
        id: 10,
        modal: 'teacher10',
        name: 'Анастасия Игоревна',
        img: teacher10,
        post: 'Педагог онлайн школы «Shine School»',
        info: [
            'Изучение языка – это не одна только монотонная зубрежка и «гармошка» из бумаги для запоминания слов. Изучение может быть гораздо более интересным с песнями любимых исполнителей, вырезками из мультфильмов, эпизодами сериалов, даже с мемами. Английский – это далеко не только школьный предмет или набор лексики, необходимый для рабочего процесса, это целая культура, погружение в которую весьма увлекательно, и я помогу вам в этом убедиться.',
            'Преподаю с 2017 года, за этот период успела поработать как в общеобразовательных организациях, так и в частных школах; веду группы как с детьми и подростками, так и со взрослыми, готовлю учеников к сдаче ОГЭ\ЕГЭ. Являюсь дипломированным педагогом, о чем свидетельствует соответствующие документы, а так же несколько сертификатов об успешной сдаче Кембриджских экзаменов TKT.',
        ]
    },
    {
        id: 11,
        modal: 'teacher11',
        name: 'Виктория Викторовна',
        img: teacher11,
        post: 'Педагог онлайн школы «Shine School»',
        info: [
            'Меня зовут Виктория. Окончила бакалавриат по направлению «английский язык и литература» с отличием, а затем магистратуру по направлению «педагогика». Регулярно прохожу курсы повышения квалификации. Преподаю английский язык школьникам и дошкольникам. На своих занятиях использую разнообразные виды деятельности, множество интерактивных заданий, мнемотехники, применяю игровые методы. Моя цель — организовать комфортную для ученика среду, в которой он будет успешно и с удовольствием осваивать английскую лексику и грамматику, а также приобретать навыки чтения, письма и устной речи.',
        ]
    },
    {
        id: 12,
        modal: 'teacher12',
        name: 'Мария Максимовна',
        img: teacher12,
        post: 'Педагог онлайн школы «Shine School»',
        info: [
            'Меня зовут Мария.',
            '4 года преподаю английский. Влюблена в язык и в культуру страны. Занимаюсь с детьми всех возрастов — от совсем малышей, до старшеклассников.',
            'За годы работы преподавателем я поняла одну важную вещь: преподавание — это не только знание грамматики или словарного запаса, но и подбор правильного ключика к ученику и установление доверия. Для меня важно создать теплую атмосферу на занятии, чтобы ребенку было комфортно изучать английский язык. Мои ученики знают, что всегда получат поддержку и помощь.',
            'Работать с детьми и подростками, видеть, как они становятся более уверенными в себе, смелыми – это лучшая награда для меня.',
        ]
    },
    {
        id: 13,
        modal: 'teacher13',
        name: 'Алина Сергеевна',
        img: teacher13,
        post: 'Педагог онлайн школы «Shine School»',
        info: [
            'Преподаю английский более 10 лет. Имею высшее педагогическое образование с квалификацией «Лингвист, преподаватель». Большой опыт работы в общеобразовательной школе и с государственными стандартами.',
            'Помогаю заговорить со всем миром на одном языке и расширять границы своих учеников. Моя любимая фраза «Человек, который владеет более, чем одним языком, живет в более просторном мире». Действительно, знание иностранного языка открывает множество возможностей: незабываемые путешествия, новые знакомства, обучение на английском языке и получение информации, которая еще не переведена на русский.',
            'Люблю путешествия, люблю людей, люблю детей и обожаю английский язык!',
        ]
    },
    {
        id: 14,
        modal: 'teacher14',
        name: 'Виктория Александровна',
        img: teacher14,
        post: 'Педагог онлайн школы «Shine School»',
        info: [
            'В 2019 году поучила степень магистра по направлению «Лингвистика». Имею международный сертификат ТКТ (Teaching Knowledge Test). Дополнительно получила образование педагога-психолога, ведь работа с детьми требует высшего профессионального мастерства. Регулярно посещаю вебинары, семинары и конференции для совершенствования методики преподавания. Изучение английского — это не только набор лексики. Обязательная составляющая — культура страны изучаемого языка. На уроках мы не только тренируем речь, но и обогащаем свой внутренний мир.',
        ]
    },
    {
        id: 15,
        modal: 'teacher15',
        name: 'Мария Олеговна',
        img: teacher15,
        post: 'Педагог онлайн школы «Shine School»',
        info: [
            'Закончила Волгоградский государственный социально-педагогический университет по специальности «Педагогическое образование с двумя профилями подготовки (русский язык, английский язык)». В 2022 году получила диплом о повышении квалификации по специальности «Переводчик в профессиональной сфере коммуникации (английский язык)».',
            'Уже 4 года обучаю русскому и английскому языкам учеников разных возрастов в онлайн формате. Придерживаюсь прагматического стиля обучения (от теории к практике), на занятиях стараюсь найти индивидуальный подход к каждому ученику, сочетаю коммуникативный и игровой методы обучения. Просто говорю о сложном, подкрепляя правила примерами из современной поп-культуры, так как самостоятельно изучаю корейский язык и понимаю боль, с которой можно столкнуться в процессе.',
        ]
    },
    {
        id: 16,
        modal: 'teacher16',
        name: 'Марина Сергеевна',
        img: teacher16,
        post: 'Педагог онлайн школы «Shine School»',
        info: [
            'Меня зовут Марина Сергеевна, преподаю английский язык более трех лет детям и взрослым.',
            'Окончила Алтайский государственный педагогический университет, лингвистический институт, по специальности «Педагогическое образование (с двумя профилями подготовки: Английский язык и Немецкий язык)».',
            'С удовольствием обучаю детей и взрослых английскому языку. Мои уроки проходят продуктивно и интересно. Я нахожу индивидуальный подход к каждому ученику и развиваю коммуникативные навыки общения на английском! Каждое занятие насыщенно интерактивными заданиями, благодаря которым изучение английского будет не только полезным, но и веселым и нескучным!',
            'Со мной Вы научитесь грамотной письменной и устной речи на английском, а также избавитесь от языкового барьера. See you in our amazing lessons!',
        ]
    },
    {
        id: 17,
        modal: 'teacher17',
        name: 'Юлия Евгеньевна',
        img: teacher17,
        post: 'Педагог онлайн школы «Shine School»',
        info: [
            'В 2014 закончила Гуманитарный Университет в г. Екатеринбурге по специальности «Регионоведение», в 2017 аспирантуру по направлению «Политические науки».',
            'В 2019 сдала международный экзамен CAE, в 2020 — педагогический Кембриджский экзамен TKT Modules 1-3, Youndg Learners.',
            'Преподаю английский больше 10 лет, в работе использую коммуникативный подход.',
            'На занятиях создаю дружелюбную атмосферу и стараюсь заинтересовать ученика.',
        ]
    },
    {
        id: 18,
        modal: 'teacher18',
        name: 'Кристина Вячеславовна',
        img: teacher18,
        post: 'Педагог онлайн школы «Shine School»',
        info: [
            'Меня зовут Кристина.',
            'На данный момент заканчиваю РГУ им. Есенина по специальности «Учитель иностранных языков». Преподаю английский детям и подросткам с 2021 года.',
            'Знание английского — это один из самых важных навыков в современном мире, поэтому изучение языка должно быть не только эффективным, но и интересным. Этому принципу я следую на своих занятиях, поэтому мои ученики никогда не скучают!',
        ]
    },
    // {
    //     id: 19,
    //     modal: 'teacher19',
    //     name: 'Ангелина Валерьевна',
    //     img: teacher19,
    //     post: 'Педагог онлайн школы «Shine School',
    //     info: [
    //         'Меня зовут Ангелина. Я преподаю английский более 4-х лет.',
    //         'Мой профиль — работа с детьми от 5 лет и выше, а также взрослые с 0.',
    //         'Закончила педагогический университет с красным дипломом по специальности «преподаватель Английского языка и географии».',
    //         'Получила опыт преподавания в частных детских центрах и онлайн-школе.',
    //         'В своей работе я придерживаюсь принципа «Учитель и ученик на одной волне». Я стараюсь заинтересовать студентов не только посещать занятия английского, а также самостоятельно заниматься вне уроков. На своих занятиях я говорю не об английском, а на английском.',
    //     ]
    // },
    {
        id: 20,
        modal: 'teacher20',
        name: 'Анна Алексеевна',
        img: teacher20,
        post: 'Педагог онлайн школы «Shine School»',
        info: [
            'Hello everyone! Меня зовут Анна, и я преподаю английский язык с любовью на протяжении 9 лет.',
            'Я не только преподаватель, но и методист, поэтому знаю, как эффективно обучать иностранным языкам. Также я создала несколько программ интенсивного обучения для школьников.',
            'Мой профиль : дети от 5 лет и старше. Я — сторонница раннего обучения и погружения в языковую среду. Со мной детки начинают говорить уже с первых уроков. На моих занятиях дети познают мир на английском: создают поделки, ставят эксперименты, виртуально исследует мир и играют в настольные игры.',
            'Я считаю, что экзамены — важная составляющая при изучении иностранных языков. Сдавая экзамен, ученик понимает , на что он уже способен и к чему ещё нужно стремиться. Я помогаю детям подготовиться к международным экзаменам: Starters, Movers, Flyers, KEY, PET, Wiseman skills.',
            'Английский язык- это уже не будущее, а настоящее! Давайте жить настоящим с интересом и пользой!',
        ]
    },
    {
        id: 21,
        modal: 'teacher21',
        name: 'Екатерина Дмитриевна',
        img: teacher21,
        post: 'Педагог онлайн школы «Shine School»',
        info: [
            'Hi everyone! Меня зовут Екатерина Дмитриевна и я преподаватель английского языка уже более трех лет.',
            'Окончила Алтайский государственный педагогический университет, лингвистический институт, по специальности «Педагогическое образование (с двумя профилями подготовки: Английский язык и Немецкий язык)».',
            'Искренне люблю английский и уверена, что смогу влюбить вас во все, что он олицетворяет: красивое звучание, действительно несложная грамматика, удивительная литература, выдающиеся люди, и, безусловно, целая многовековая культура!',
            'Жду вас на уроках, где вы почувствуете себя уверенно и комфортно, ощутите поддержку на каждом этапе обучения, а также поймете, что учить английский легко и интересно. See you!',
        ]
    },
    {
        id: 22,
        modal: 'teacher22',
        name: 'Марина Викторовна',
        img: teacher22,
        post: 'Педагог онлайн школы «Shine School»',
        info: [
            'Привет! Я действующий преподаватель китайского языка с 2015 года.',
            'Получила педагогическое образование с двумя профилями подготовки: китайский язык и английский язык. Жила и работала в Китае; практиковала язык в Сингапуре, Малайзии и Тайланде. Обучаю с нуля детей от 8-10 лет, подростков и взрослых. Готовлю к HSK. Я точно знаю, как с легкостью и юмором показать, что писать иероглифы и болтать на китайском не так уж и страшно!;)',
        ]
    },
    {
        id: 23,
        modal: 'teacher23',
        name: 'Вероника Сергеевна',
        img: teacher23,
        post: 'Педагог онлайн школы «Shine School»',
        info: [
            'Окончила Пятигорский государственный университет в 2018, изучала английский и китайский языки, также проходила языковые курсы китайского в 2017 году в Шанхайском университете иностранных языков, множество онлайн курсов по HSK и т.д.',
            'Преподаю иностранные языки вот уже 6 лет, как оффлайн, так и онлайн. Два года работала в Китае преподавателем, а также во множество онлайн школах, продолжаю свою деятельность до сих пор и получаю от нее огромное удовольствие! Приходите ко мне на занятия китайского, где Вы сами сможете убедиться насколько интересным можно процесс изучения нового языка!',
        ]
    },
    {
        id: 24,
        modal: 'teacher24',
        name: 'Мария Васильевна',
        img: teacher24,
        post: 'Педагог онлайн школы «Shine School»',
        info: [
            '你好！我是玛丽老师。Провожу онлайн-занятия для детей и взрослых. Основной акцент во всех занятиях — на разговорной практике. Да, даже маленькие детки учатся говорить по-китайски с первых минут занятия.',
            'Программу подстраиваю индивидуально под желания ученика. Туда входят: аудио- и видео-материалы, задания на отработку грамматики, на работу с видео. Программа основана на учебных материалах — HSK Standard Course — для взрослых; — YCT — для детей от 5 до 15 лет.',
            'Опыт преподавания более 4-х лет. Работаю с дошкольниками, подростками и взрослыми. Со мной взрослые ученики изучают китайский язык для себя, чтобы подтянуть программу на языковом факультете. Дети поступают в школу с китайским уклоном и уже опережают основную программу.',
            'В 2018 году закончила Южно-уральский государственный университет по специальности лингвистика и международные отношения. Училась в Китае в Zhejiang Ocean University. Есть подтвержденный сертификат HSK5.',
        ]
    },
    {
        id: 25,
        modal: 'teacher25',
        name: 'Инна Геннадьевна',
        img: teacher25,
        post: 'Педагог онлайн школы «Shine School»',
        info: [
            '🙌 Немного расскажу о себе ⬇️ ➡️ Меня зовут Инна (китайское имя Shen Ying)',
            '➡️ Я окончила Новосибирский государственный педагогический университет по специальности "Культурология и китайский язык"🇨🇳',
            '➡️ Проходила обучение в Шеньянском политехническом университете 沈阳理工大学 на курсе "Китайский язык для иностранцев" (КНР, провинция ЛяоНин, г.ШэньЯн,).',
            '➡️ Выйграла конкурс и стажировку в Шаньдунский Педагогический университет 山东师范大学 (КНР, провинция ШаньДун, г.Цзинань).',
            '🗣 Жила и работала в КНР на протяжении нескольких лет (переводчик, преподаватель русского и английского языков в школе иностранных языков 杨格外语学校).',
            '👉 В настоящее время обучаю китайскому языку детей от 10 лет и взрослых 🫡🇨🇳',
            '📚 В обучении использую различные учебные пособия, подстраивая программу обучения под потребности каждого ученика. На уроках уделяю внимание каждому аспекту изучения языка: аудирование, чтение, письмо.',
        ]
    },
    {
        id: 26,
        modal: 'teacher26',
        name: 'Ольга Александровна',
        img: teacher26,
        post: 'Педагог онлайн школы «Shine School»',
        info: [
            'Стаж работы с детьми более 10 лет. Имею высшее педагогическое образование .',
            'Постоянно совершенствую свои навыки, ищу и осваиваю новые современные подходы и методы в обучении. Стараюсь найти подход к любому ученику и передать детям не только знания, но и любовь к обучению. Я влюблена в дело, которое делаю и в детей, с которыми работаю.',
        ]
    },
    {
        id: 27,
        modal: 'teacher27',
        name: 'Анна Игорьевна',
        img: teacher27,
        post: 'Педагог онлайн школы «Shine School»',
        info: [
            'Мой педагогический стаж 8 лет. У меня высшее педагогическое образование,и я стараюсь постоянно пополнять свои знания. Я очень люблю дело, которым занимаюсь и ценю в каждом своём ученике личность.',
            'Всегда нахожу в ученике сильную сторону и много внимания уделяю мотивации. Максимально доступно и интересно планирую занятия,ориентируясь на особенности ребёнка. Работаю с учётом нейропсихологического подхода.',
        ]
    },
];