import React from "react";

import Button from "./Button";

import EducationalInfo from './docs/vipiska-iz-reestra-licenziy.pdf';

import logo from '../img/header/logo.svg';
import burger from '../img/header/burger.svg';

const handleLogoClick = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
}

class Header extends React.Component {

    state = {
        isDropdownOpen: false,
        isSubmenuOpen: false
    };

    handleDropdownToggle = () => {
        this.setState(prevState => ({
            isDropdownOpen: !prevState.isDropdownOpen
        }));
    };

    handleSubmenuToggle = () => {
        this.setState(prevState => ({
            isSubmenuOpen: !prevState.isSubmenuOpen
        }));
    };

    handleClickOutside = (event) => {
        if (this.dropdownRef && !this.dropdownRef.contains(event.target) && !this.submenuRef.contains(event.target)) {
            this.setState({ isDropdownOpen: false, isSubmenuOpen: false });
        }
    };

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
    }

    render() {
        const { isDropdownOpen, isSubmenuOpen } = this.state;

        return (
            <header className="header">
                <img src={logo} onClick={handleLogoClick} alt="logo" id="pointer" className="header__logo" />
                <div className="header__content special-button-container">
                    <nav className="header__content__nav">
                        <a href="#about" className="header__content__nav__a">о нас</a>
                        <a href="#teachers" className="header__content__nav__a">преподаватели</a>
                        <a href="#feedback" className="header__content__nav__a">отзывы</a>
                        <a href="#questions" className="header__content__nav__a">вопрос-ответ</a>
                        <div className="header__content__nav__a" onClick={this.handleSubmenuToggle} ref={(node) => this.submenuRef = node}>
                            выбрать<br />направление
                            {isSubmenuOpen && (
                                <div className="header__content__nav__a__submenu">
                                    <a href="#english" className="header__content__nav__a__submenu__a">Английский язык</a>
                                    <a href="#chinese" className="header__content__nav__a__submenu__a">Китайский язык</a>
                                    <a href="#kids" className="header__content__nav__a__submenu__a">Подготовка к школе</a>
                                    <a href="#school" className="header__content__nav__a__submenu__a">Начальная школа</a>
                                    <a href="#oge" className="header__content__nav__a__submenu__a">Подготовка к ОГЭ</a>
                                </div>
                            )}
                        </div>
                        <a href={EducationalInfo} target="_blank" rel="noopener noreferrer" id="pointer" className="header__content__nav__a">
                            образовательная<br />лицензия
                        </a>
                        {/* <div data-hystmodal='#license' id="pointer" className="header__content__nav__a">образовательная<br />лицензия</div> */}
                    </nav>
                    <Button text="войти на урок" isLong={true} link="https://shineschool.ru/"/>
                    <div
                        onClick={this.handleDropdownToggle}
                        ref={(node) => this.dropdownRef = node}
                        className={`header__nav__dropdown ${isDropdownOpen ? 'active' : ''}`}
                    >
                        <div className="header__content__burger">
                            <img
                                src={burger} alt="menu"
                            />
                        </div>
                        <div className="header__content__nav__dropdown-content">
                            <a href="#about" className="header__content__nav__dropdown-content__a">о нас</a>
                            <a href="#teachers" className="header__content__nav__dropdown-content__a">преподаватели</a>
                            <a href="#feedback" className="header__content__nav__dropdown-content__a">отзывы</a>
                            <a href="#questions" className="header__content__nav__dropdown-content__a">вопрос-ответ</a>
                            {/* <a href="#way" className="header__content__nav__dropdown-content__a">выбрать направление</a> */}
                            <a href="#english" className="header__content__nav__a__submenu__a">Английский язык</a>
                            <a href="#chinese" className="header__content__nav__a__submenu__a">Китайский язык</a>
                            <a href="#kids" className="header__content__nav__a__submenu__a">Подготовка к школе</a>
                            <a href="#school" className="header__content__nav__a__submenu__a">Начальная школа</a>
                            <a href="#oge" className="header__content__nav__a__submenu__a">Подготовка к ОГЭ</a>
                            <div data-hystmodal='#license' id="pointer" className="header__content__nav__dropdown-content__a">образовательная лицензия</div>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

export default Header;




































// import React from "react";
// import logo from '../img/header/logo.svg';
// import burger from '../img/header/burger.svg';

// const handleLogoClick = () => {
//     window.scrollTo({
//         top: 0,
//         behavior: 'smooth',
//     });
// }

// class Header extends React.Component {

//     state = {
//         isDropdownOpen: false
//     };

//     handleDropdownToggle = () => {
//         this.setState(prevState => ({
//             isDropdownOpen: !prevState.isDropdownOpen
//         }));
//     };

//     handleClickOutside = (event) => {
//         if (this.dropdownRef && !this.dropdownRef.contains(event.target)) {
//             this.setState({ isDropdownOpen: false });
//         }
//     };

//     componentDidMount() {
//         document.addEventListener('click', this.handleClickOutside);
//     }

//     componentWillUnmount() {
//         document.removeEventListener('click', this.handleClickOutside);
//     }

//     render() {
//         const { isDropdownOpen } = this.state;

//         return (
//             <header className="header">
//                 <img src={logo} onClick={handleLogoClick} alt="logo" id="pointer" className="header__logo" />
//                 <div className="header__content">
//                     <nav className="header__content__nav">
//                         <a href="#about" className="header__content__nav__a">о нас</a>
//                         <a href="#teachers" className="header__content__nav__a">преподаватели</a>
//                         <a href="#feedback" className="header__content__nav__a">отзывы</a>
//                         <a href="#questions" className="header__content__nav__a">вопрос-ответ</a>
//                         <a href="#way" className="header__content__nav__a">выбрать<br />направление</a>
//                         <div data-hystmodal='#license' id="pointer" className="header__content__nav__a">образовательная<br />лицензия</div>
//                     </nav>
//                     <div className="header__content__button" data-hystmodal='#form'>
//                         <button className="header__content__button__hover">войти на урок</button>
//                     </div>
//                     <div
//                         onClick={this.handleDropdownToggle}
//                         ref={(node) => this.dropdownRef = node}
//                         className={`header__nav__dropdown ${isDropdownOpen ? 'active' : ''}`}
//                     >
//                         <div className="header__content__burger">
//                             <img
//                                 src={burger} alt="menu"
//                             />
//                         </div>
//                         <div className="header__content__nav__dropdown-content">
//                             <a href="#about" className="header__content__nav__dropdown-content__a">о нас</a>
//                             <a href="#teachers" className="header__content__nav__dropdown-content__a">преподаватели</a>
//                             <a href="#feedback" className="header__content__nav__dropdown-content__a">отзывы</a>
//                             <a href="#questions" className="header__content__nav__dropdown-content__a">вопрос-ответ</a>
//                             <a href="#way" className="header__content__nav__dropdown-content__a">выбрать направление</a>
//                             <div data-hystmodal='#license' id="pointer" className="header__content__nav__dropdown-content__a">образовательная лицензия</div>
//                         </div>
//                     </div>
//                 </div>
//             </header>
//         );
//     }
// }

// export default Header;


















































































// import React from "react";
// import logo from '../img/header/logo.svg'
// import burger from '../img/header/burger.svg'

// const handleLogoClick = () => {
//     window.scrollTo({
//         top: 0,
//         behavior: 'smooth', // Добавляем плавное скроллирование
//     });
// }

// class Header extends React.Component {

//     state = {
//         isDropdownOpen: false
//       };
    
//     handleDropdownToggle = () => {
//         this.setState(prevState => ({
//             isDropdownOpen: !prevState.isDropdownOpen
//         }));
//     };

//     render() {

//         const { isDropdownOpen } = this.state;

//         return(
//             <header className="header">
//                 <img src={logo} onClick={handleLogoClick} alt="logo" id="pointer" className="header__logo" />
//                 <div className="header__content">
//                     <nav className="header__content__nav">
//                         <a href="#about" className="header__content__nav__a">о нас</a>
//                         <a href="#teachers" className="header__content__nav__a">преподаватели</a>
//                         <a href="#feedback" className="header__content__nav__a">отзывы</a>
//                         <a href="#questions" className="header__content__nav__a">вопрос-ответ</a>
//                         <a href="#way" className="header__content__nav__a">выбрать<br/>направление</a>
//                         <div  data-hystmodal='#license' id="pointer" className="header__content__nav__a">образовательная<br/>лицензия</div>
//                     </nav>
//                     <div className="header__content__button" data-hystmodal='#form'>
//                         <button className="header__content__button__hover">войти на урок</button>
//                     </div>
//                     <div
//                         onMouseEnter={this.handleDropdownToggle}
//                         onMouseLeave={this.handleDropdownToggle}
//                         className={`header__nav__dropdown ${isDropdownOpen ? 'active' : ''}`}
//                     >
//                         <div className="header__content__burger">
//                             <img 
//                                 src={burger} alt="menu" 
//                             />
//                         </div>
//                         <div className="header__content__nav__dropdown-content">
//                             <a href="#about" className="header__content__nav__dropdown-content__a">о нас</a>
//                             <a href="#teachers" className="header__content__nav__dropdown-content__a">преподаватели</a>
//                             <a href="#feedback" className="header__content__nav__dropdown-content__a">отзывы</a>
//                             <a href="#questions" className="header__content__nav__dropdown-content__a">вопрос-ответ</a>
//                             <a href="#way" className="header__content__nav__dropdown-content__a">выбрать направление</a>
//                             <div  data-hystmodal='#license' id="pointer" className="header__content__nav__dropdown-content__a ">образовательная лицензия</div>
//                         </div>
//                     </div>
//                 </div>
//             </header>
//         )
//         }
// };

// export default Header;





