import React from 'react';

const Button = ({ text, isLong, onClick, modalTarget, link, anchor }) => {
    if (modalTarget) {
        return (
            <div className="button-container">
                <button className={`button ${isLong ? 'button--long' : ''}`} data-hystmodal={modalTarget}>
                    {text}
                </button>
            </div>
        );
    }
    if (link) {
        return (
            <div className="button-container">
                <button className={`button ${isLong ? 'button--long' : ''}`} onClick={() => window.open(link, '_blank')}>
                    {text}
                </button>
            </div>
        );
    }
    if (anchor) {
        return (
            <div className="button-container">
                <button className={`button ${isLong ? 'button--long' : ''}`}>
                    <a href={`#${anchor}`} className="button-container-a">
                        {text}
                    </a>
                </button>
                
            </div>
        );
    }
    return (
        <div className="button-container">
            <button className={`button ${isLong ? 'button--long' : ''}`} onClick={onClick}>
                {text}
            </button>
        </div>
    );
};

export default Button;
